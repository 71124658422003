<template>
  <div id="chips-content-box">
    <div
      class="chips-banner"
      v-if="
        stormToBookTagData.image == '' || stormToBookTagData.image == undefined
      "
    >
      <div class="chips-banner-box">
        <div class="chips-banner-left">#{{ stormToBookTagData.name }}</div>
        <div
          class="chips-banner-right"
          v-if="stormToBookTagData.followed"
          @click="bookTag('okBook', stormToBookTagData.id)"
        >
          {{ getString(strings.StormBookTag_Subscribed) }}
        </div>
        <div
          class="chips-banner-right"
          @click="bookTag('noBook', stormToBookTagData.id)"
          v-else
        >
          {{ getString(strings.Echart_Edit_Value_Subscribe) }}
        </div>
      </div>
    </div>
    <div
      class="chips-banner"
      :style="
        'background-repeat: no-repeat;background-size: 100%;width: 100%;height: 130px;margin: 0 auto;display: flex;align-items: center; background-image:url(' +
        stormToBookTagData.image +
        ') ; '
      "
      v-else
    >
      <div class="chips-banner-box">
        <div class="chips-banner-left">#{{ stormToBookTagData.name }}</div>
        <div
          class="chips-banner-right"
          v-if="stormToBookTagData.followed"
          @click="bookTag('okBook', stormToBookTagData.id)"
        >
          {{ getString(strings.StormBookTag_Subscribed) }}
        </div>
        <div
          class="chips-banner-right"
          @click="bookTag('noBook', stormToBookTagData.id)"
          v-else
        >
          {{ getString(strings.Echart_Edit_Value_Subscribe) }}
        </div>
      </div>
    </div>
    <div class="chips-content">
      <!-- 操作标题栏   -->
      <div class="chips-header-menu">
        <div class="chips-menu-name-box">
          <div
            class="chips-menu-name"
            v-for="(item, index) in chipsMenuNameList"
            :key="index"
            :class="
              chipsMenuTitleNumber == index ? 'chips-menu-name-style' : ''
            "
            @click="chipsMenuTitleBotton(index)"
          >
            {{ getString(item) }}
          </div>
        </div>
      </div>
      <div class="chips-menu-subject">
        <!-- 推荐 -->
        <div v-if="chipsMenuTitleNumber == 0" class="chips-menu-recommend">
          <div
            class="recommend-user-chips"
            v-for="(item, index) in chipsFeedsList"
            :key="index"
          >
            <div class="user-message">
              <div class="user-header-img" @click="getUserProfile(item.userId)">
                <img
                  :src="item.userAvatar"
                  onerror="this.src='/static/img/userData/avatar_yellow_man.png'"
                  alt=""
                />
              </div>
              <div class="user-text">
                <div class="user-name" @click="getUserProfile(item.userId)">
                  <span>{{ item.username }}</span>
                </div>
                <div class="user-time">
                  <span>{{ timestampToTimeDHM(item.createTime) }}</span>
                </div>
              </div>
            </div>
            <!-- 封面 -->
            <div class="chips-potato-chips">
              <div class="chips-pc-box">
                <div class="chips-pc-cover" @click="commentCrips(item.id)">
                  <img :src="item.cover" alt="" />
                </div>
                <div class="chips-pc-text">
                  <div class="chips-pc-title" @click="commentCrips(item.id)">
                    <span>{{ item.title }}</span>
                  </div>
                  <div
                    class="chips-pc-text-content"
                    @click="commentCrips(item.id)"
                  >
                    <span>{{ item.subtitle }}</span>
                  </div>
                  <div class="chips-pc-bottom">
                    <div class="chips-pc-style">
                      <span
                        v-for="(itemStyle, index) in item.tags"
                        :key="index"
                        @click="stormToBookTag(itemStyle.id)"
                        >#{{ itemStyle.name }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- 操作详情 -->
              <div class="chips-pc-detail-box">
                <div class="chips-look-accout">
                  <span
                    >{{ item.viewCount
                    }}{{ getString(strings.Mind_Storm_Views) }}</span
                  >
                </div>
                <div class="chips-details">
                  <div
                    class="chips-details-single"
                    @click="commentCrips(item.id)"
                  >
                    <img
                      src="../../../static/img/userData/news_logo.png"
                      alt=""
                    />
                    <span></span>
                  </div>
                  <div
                    v-if="item.voted"
                    class="chips-details-single"
                    @click="postCrispsVoteCrisps(index, item.id)"
                  >
                    <div class="chips-detials-single-box">
                      <img
                        src="../../../static/img/userData/spot_red_log.svg"
                        alt=""
                      />
                      <span>{{ item.voteCount }}</span>
                    </div>
                  </div>
                  <div
                    v-else
                    class="chips-details-single"
                    @click="postCrispsVoteCrisps(index, item.id)"
                  >
                    <div class="chips-detials-single-box">
                      <img
                        src="../../../static/img/userData/like_log.png"
                        alt=""
                      />
                      <span v-if="item.voteCount != 0">{{
                        item.voteCount
                      }}</span>
                    </div>
                  </div>
                  <a-popover
                    v-model="item.visible"
                    placement="bottomLeft"
                    trigger="click"
                  >
                    <template slot="content">
                      <div
                        class="chips-delete-content"
                        @click="notInterested(index, item.id)"
                      >
                        <img
                          src="../../../static/img/userData/not-interested-logo.png"
                          alt=""
                        />
                        <span>{{
                          getString(strings.Mind_Storm_No_Tinterested)
                        }}</span>
                      </div>
                    </template>
                    <template slot="content">
                      <div
                        class="chips-delete-content"
                        @click="reportCrisps(index, item.id)"
                      >
                        <img
                          src="../../../static/img/userData/report-crisps-logo.png"
                          alt=""
                        />
                        <span>{{ getString(strings.Mind_Storm_Report) }}</span>
                      </div>
                    </template>
                    <a-button>
                      <img
                        src="../../../static/img/userData/details_x_logo.png"
                        alt=""
                      />
                    </a-button>
                  </a-popover>
                </div>
              </div>
              <div class="chips-pc-comment" v-if="item.topComments.length > 0">
                <div
                  class="chips-pc-comment-content"
                  v-for="(comments, index) in item.topComments"
                  :key="index"
                >
                  <div v-if="index < 2">
                    <div class="comment-user-name">
                      {{ comments.username }}:
                    </div>
                    <div class="comment-user-text">
                      {{ comments.content }}
                    </div>
                  </div>
                </div>
                <!-- <div class="chips-pc-comment-content">
                  <div class="comment-user-name">导图小达人1 :</div>
                  <div class="comment-user-text">哇~你的导图真是太好看了</div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 举报模态框 -->
    <a-modal
      class="report-crisps-model"
      :centered="true"
      v-model="reportCrispsVisible"
      :title="getString(strings.Mind_Storm_Report)"
      @ok="reportCrispsHandleOk"
      :cancelText="getString(strings.Global_Cancel)"
      :okText="getString(strings.Global_Ok)"
    >
      <div class="report-crisps-style-box">
        <div
          class="report-crisps-style"
          v-for="(item, index) in reportCrispsContentList"
          :key="index"
          @click="reportCrispsClick(index)"
        >
          <div class="report-crisps-left">
            {{ getString(item) }}
          </div>
          <div class="report-crisps-right">
            <div
              class="report-crisps-defualt-click"
              :class="
                reportCrispsContentListNumber == index
                  ? 'report-defualt-click'
                  : ''
              "
            ></div>
            <img
              v-if="reportCrispsContentListNumber == index"
              src="../../../static/img/userData/crisps-reporet-click.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  postCrispsBoilingHome,
  postCrispsFollowTag,
  postCrispsGetTagPage,
  postCrispsListBoilingTags,
  postCrispsModifyBoilingTags,
  postCrispsNotInterested,
  postCrispsPullFeed,
  postCrispsReportCrisps,
  postCrispsUnfollowTag,
  postCrispsUnvoteCrisps,
  postCrispsView,
  postCrispsVoteCrisps,
} from "../../common/netWork/crisps_api";
import httpImageToPrefix from "../../utils/httpImageToPrefix";
import dataTimeToFormats from "../../utils/timestampToTime";
import crisps from "../../assets/css/crisps/crisps.less";
import { mapMutations } from "vuex";
import strings from "../../common/lang/strings";
import getString from "../../common/lang/language";
import Util from '../../utils/Util';
if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  // var { ipcRenderer } = window.require("electron");
  var ipcRenderer = window.ipcRenderer
}
export default {
  data() {
    return {
      chipsMenuNameList: [strings.Mind_Storm_Report],
      chipsMenuTitleNumber: 0, // 顶部菜单栏 0 推荐 1沸点
      chipsFeedsList: [], //推荐数据
      crispsClickId: "", //当前选中的id
      reportCrispsVisible: false, //举报面板
      reportCrispsContentList: [
        strings.Mind_Storm_Pornographic, //色情低俗
        strings.Mind_Storm_Advertising_Harassment, //广告骚扰
        strings.Mind_Storm_Political_Religion, //政治宗教
        strings.Mind_Storm_Infringement, //侵权（肖像、诽谤、抄袭、冒用）
        strings.Mind_Storm_Prohibited_Content, //违禁内容
        strings.Mind_Storm_Spread_Rumors, //造谣传谣
      ],
      reportCrispsContentListNumber: 0, //举报选项
      reportCrispsClickContent: "", //举报选中的内容
      stormToBookTagData: {}, //订阅内容
      nowElectron: false,
      strings: strings,
    };
  },
  mounted() {
    this.nowElectron = this.$tools.isElectron();
    this.postCrispsGetTagPage();
  },
  methods: {
    ...mapMutations(["showUserDataModel"]),
    getString(i) {
      return getString(i);
    },
    openMind(item) {
      //打开导图
      ipcRenderer.send("openCalendarWindow", item);
    },
    //获取订阅内容
    postCrispsGetTagPage() {
      postCrispsGetTagPage(
        { tagId: Util.getQueryId(this), lastId: "" },
        (res) => {
          this.stormToBookTagData = res;
          this.chipsFeedsList = res.crispsFeeds;
          let userAvatarLength = this.chipsFeedsList.length;
          let userAvatar = this.chipsFeedsList;
          for (let i = 0; i < userAvatarLength; i++) {
            var avatar = userAvatar[i].userAvatar;
            this.chipsFeedsList[i].userAvatar = httpImageToPrefix(avatar);
          }

          for (let i = 0; i < userAvatarLength; i++) {
            var cover = userAvatar[i].cover;
            this.chipsFeedsList[i].cover = httpImageToPrefix(cover);
          }
          // console.log(this.stormToBookTagData);
        }
      );
    },
    //订阅分类
    bookTag(bookTag, id) {
      if (bookTag == "okBook") {
        postCrispsUnfollowTag(
          { tagId: id },
          (res) => {
            this.$message.success(getString(strings.Message_Tips_Unsubscribe_Success));
            this.stormToBookTagData.followed = false;
          },
          (error) => {
            this.$message.error(error.desc);
          }
        );
      } else if (bookTag == "noBook") {
        postCrispsFollowTag(
          { tagId: id },
          (res) => {
            this.$message.success(getString(strings.Message_Tips_Subscription_Success));
            this.stormToBookTagData.followed = true;
          },
          (error) => {
            this.$message.error(error.desc);
          }
        );
      }
    },
    //顶部菜单栏 0 推荐 1沸点
    chipsMenuTitleBotton(index) {
      this.chipsMenuTitleNumber = index;
      if (index == 1) {
        this.postCrispsBoilingHome();
      }
    },
    //标签跳转订阅
    stormToBookTag(id) {
      if (this.nowElectron) {
        let url = "/Home/StormBookTag/?id=" + id;
        this.openMind(url);
      } else {
        const newWindow = window.open();
        const routerData = this.$router.resolve({
          path: "/Home/StormBookTag/",
          query: {
            id: id,
          },
        });
        newWindow.location = routerData.href;
      }
    },
    //封面 or 评论跳转
    commentCrips(id) {
      if (this.nowElectron) {
        let url = "/c/" + id;
        this.openMind(url);
      } else {
        let newWindow = window.open();
        const routerData = this.$router.resolve({
          path: "/c/" + id,
        });
        newWindow.location = routerData.href;
      }
    },
    //跳转个人信息
    getUserProfile(id) {
      this.showUserDataModel({
        accout: true,
        userId: id,
        xfrom: "crisps",
        fromId: id,
      });
    },
    //风暴点赞
    postCrispsVoteCrisps(index, id) {
      //voteCrispsAccout true 为取消点赞 false 为点赞
      let voteCrispsAccout = this.chipsFeedsList[index].voted;
      if (voteCrispsAccout) {
        postCrispsUnvoteCrisps(
          { crispsId: id },
          (res) => {
            if (this.chipsFeedsList[index].voteCount != 0) {
              this.chipsFeedsList[index].voteCount -= 1;
            }
            this.chipsFeedsList[index].voted = false;
          },
          (error) => {
            this.$message.error(error.desc);
          }
        );
      } else {
        postCrispsVoteCrisps(
          { crispsId: id },
          (res) => {
            this.chipsFeedsList[index].voteCount += 1;
            this.chipsFeedsList[index].voted = true;
          },
          (error) => {
            this.$message.error(error.desc);
          }
        );
      }
    },
    //不感兴趣
    notInterested(index, id) {
      this.chipsFeedsList[index].visible = false;
      postCrispsNotInterested(
        { crispsId: id },
        () => {
          this.chipsFeedsList.splice(index, 1);
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
    },
    //打开举报面板
    reportCrisps(index, id) {
      this.reportCrispsVisible = true;
      this.chipsFeedsList[index].visible = false;
      this.crispsClickId = { index, id };
    },
    //举报
    reportCrispsHandleOk() {
      this.reportCrispsVisible = false;
      var crispsId = this.crispsClickId.id;
      var reason = this.reportCrispsClickContent;
      postCrispsReportCrisps(
        { crispsId, reason },
        (res) => {
          this.chipsFeedsList.splice(this.crispsClickId.index, 1);
          this.$message.success(getString(strings.Mind_Storm_Report_Successful));
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
    },
    //举报选项
    reportCrispsClick(index) {
      this.reportCrispsContentListNumber = index;
      this.reportCrispsClickContent = this.reportCrispsContentList[index];
    },
    //时间转化
    timestampToTimeDHM(timestamp) {
      return dataTimeToFormats.formatTime(timestamp);
    },
  },
};
</script>

<style lang="less" scoped>
#chips-content-box {
  width: 100%;
  background-color: #ffffff;
  margin: 0 auto;
  .chips-banner {
    background-image: url("../../../static/img/userData/storm_banner.png");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
    height: 130px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .chips-banner-box {
      margin: 0 auto;
      max-width: 1200px;
      max-height: 130px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .chips-banner-left {
        font-size: 22px;
        font-family: Noto Sans SC;
        /*font-weight: bold;*/
        line-height: 30px;
        color: #ffffff;
        opacity: 1;
      }
      .chips-banner-right {
        width: 102px;
        height: 38px;
        background: #ec705a;
        opacity: 1;
        border-radius: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        cursor: pointer;
        transition: 0.5s;
      }
      .chips-banner-right:hover {
        background-color: #ff7354;
        transition: 0.5s;
        box-shadow: 0px 3px 3px rgba(161, 161, 161, 0.5);
      }
    }
  }
  .chips-content {
    margin: 0 auto;
    width: 1200px;
    margin-top: 38px;
    //菜单栏
    .chips-header-menu {
      border-bottom: 1px solid #f8f2f0;
      .chips-menu-name-box {
        display: flex;
        .chips-menu-name {
          height: 33px;
          font-size: 18px;
          font-family: Noto Sans SC;
          font-weight: 400;
          line-height: 25px;
          color: #333333;
          opacity: 1;
          margin-right: 70px;
          cursor: pointer;
          transition: 0.5s;
        }
        .chips-menu-name-style {
          border-bottom: 2px solid #ec705a;
          color: #ec705a;
          transition: 0.5s;
        }
      }
    }
    //推荐沸点
    .chips-menu-subject {
      .chips-menu-recommend {
        .recommend-user-chips {
          min-height: 222px;
          margin-top: 20px;
          .user-message {
            width: 156px;
            height: 48px;
            display: flex;
            align-items: center;
            .user-header-img {
              width: 48px;
              height: 48px;
              border-radius: 50%;
              cursor: pointer;
              img {
                width: 48px;
                height: 48px;
                border-radius: 50%;
              }
            }
            .user-text {
              margin-left: 16px;
              .user-name {
                width: 88px;
                height: 22px;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 16px;
                font-family: Noto Sans SC;
                font-weight: 400;
                line-height: 22px;
                color: #333333;
                opacity: 1;
                cursor: pointer;
              }
              .user-time {
                font-size: 14px;
                font-family: Noto Sans SC;
                font-weight: 400;
                line-height: 20px;
                color: #999999;
                opacity: 1;
              }
            }
          }
          .chips-potato-chips {
            width: 100%;
            .chips-pc-box {
              display: flex;
              margin-top: 20px;
              .chips-pc-cover {
                width: 140px;
                height: 126px;
                min-width: 140px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(0, 0, 0, 0);
                border: 1px solid #d6d6d6;
                opacity: 1;
                border-radius: 10px;
                cursor: pointer;
              }
              .chips-pc-cover img {
                transition: 0.5s;
                cursor: pointer;
              }
              .chips-pc-cover img:hover {
                transform: scale(1.1);
                transition: 0.5s;
                cursor: pointer;
              }
              .chips-pc-text {
                width: 100%;
                margin-left: 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                .chips-pc-title {
                  height: 25px;
                  font-size: 18px;
                  font-family: Noto Sans SC;
                  /*font-weight: bold;*/
                  line-height: 25px;
                  color: #333333;
                  opacity: 1;
                  span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    cursor: pointer;
                    transition: 0.3s;
                  }
                  span:hover {
                    color: #ec705a;
                    transition: 0.3s;
                  }
                }
                .chips-pc-text-content {
                  width: 100%;
                  height: 22px;
                  font-size: 16px;
                  font-family: Noto Sans SC;
                  font-weight: 400;
                  line-height: 22px;
                  color: #666666;
                  opacity: 1;
                  span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    transition: 0.3s;
                    cursor: pointer;
                  }
                  span:hover {
                    color: #ec705a;
                    transition: 0.3s;
                  }
                }
                .chips-pc-bottom {
                  width: 500px;
                  font-size: 14px;
                  font-family: Noto Sans SC;
                  font-weight: 400;
                  line-height: 20px;
                  color: #999999;
                  opacity: 1;
                  display: flex;
                  position: relative;
                  align-items: center;
                  .chips-pc-style {
                    position: absolute;
                    left: 0;
                    span {
                      margin-right: 35px;
                      color: #ec705a;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
            .chips-pc-detail-box {
              height: 20px;
              margin-top: 20px;
              display: flex;
              .chips-look-accout {
                width: 162px;
                span {
                  font-size: 14px;
                  font-family: Noto Sans SC;
                  font-weight: 400;
                  line-height: 20px;
                  color: #999999;
                  opacity: 1;
                }
              }
              .chips-details {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 190px;
                .chips-details-single {
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  img {
                    width: 20px;
                    margin-right: 10px;
                  }
                  .chips-detials-single-box {
                    display: flex;
                    justify-content: space-between;
                    align-content: center;
                    min-width: 40px;
                  }
                }
                button {
                  border: none;
                  background-color: transparent;
                }
              }
            }
            .chips-pc-comment {
              height: 50px;

              margin-top: 10px;
              .chips-pc-comment-content {
                display: flex;
                div {
                  display: flex;
                  align-items: center;
                  .comment-user-name {
                    color: #e5654e;
                    font-size: 14px;
                    font-weight: 400;
                  }
                  .comment-user-text {
                    font-size: 14px;
                    font-family: Noto Sans SC;
                    font-weight: 400;
                    line-height: 20px;
                    color: #666666;
                    opacity: 1;
                    margin-left: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
// ant design vue

.ant-popover {
  z-index: 1000 !important;
}
.chips-delete-button {
  cursor: pointer;
}
.ant-popover-inner-content {
  padding: 18px 23px;
}
.chips-delete-content {
  display: flex;
  align-items: center;
  height: 40px;
  width: 146px;
  cursor: pointer;
  span {
    margin-left: 10px;
  }
}
</style>